import { Observable, catchError, map } from 'rxjs';
import { axiosInstance, axiosIpfsInstance } from '../../../helpers/axios';
import axiosSecureInstance from '../../../helpers/axiosSecure';
import { useSnackbar } from '../../../utils/snackbar';

export const useNetworkService = () => {
  const { showMessage } = useSnackbar();

  const fromPromise = (promise: Promise<any>) => {
    return new Observable<any>((subscriber) => {
      promise
        .then((value) => {
          subscriber.next(value);
          subscriber.complete();
        })
        .catch((error) => {
          subscriber.error(error);
        });
    });
  };

  const getData = (url: string) => {
    return fromPromise(axiosInstance.get(url))
      .pipe(map(({ data }) => data))
      .pipe(catchError((error) => getError(error)));
  };

  const getBlobData = (url: string) => {
    return fromPromise(axiosInstance.get(url, {responseType: "blob"}))
      .pipe(map(({ data }) => data))
      .pipe(catchError((error) => getError(error)));
  };

  const postData = (url: string, params?: any) => {
    return fromPromise(axiosInstance.post(url, params))
      .pipe(map(({ data }) => data))
      .pipe(catchError((error) => getError(error)));
  };

  const putData = (url: string, params?: any) => {
    return fromPromise(axiosInstance.put(url, params))
      .pipe(map(({ data }) => data))
      .pipe(catchError((error) => getError(error)));
  };

  const getSecureData = (url: string) => {
    return fromPromise(axiosSecureInstance.get(url))
      .pipe(map(({ data }) => data))
      .pipe(catchError((error) => getError(error)));
  };

  const postSecureData = (url: string, params?: any) => {
    return fromPromise(axiosSecureInstance.post(url, params))
      .pipe(map(({ data }) => data))
      .pipe(catchError((error) => getError(error)));
  };

  const putSecureData = (url: string, params?: any) => {
    return fromPromise(axiosSecureInstance.put(url, params))
      .pipe(map(({ data }) => data))
      .pipe(catchError((error) => getError(error)));
  };

  const getByIpfsLink = (url: string) => {
    return fromPromise(axiosIpfsInstance.get(url.replace('ipfs://', '')))
      .pipe(map(({ data }) => data))
      .pipe(catchError((error) => getError(error)));
  };

  const getError = (error: any) => {
    showErrorMessage(error);
    throw error;
  };

  const showErrorMessage = (error: any) => {
    if (showMessage) {
      showMessage({
        message:
          error?.message ?? error?.error?.message ?? error?.response?.data?.details?.[0] ?? error,
        duration: 5000,
        severity: 'error',
      });
    }
  };

  return {
    fromPromise,
    getData,
    postData,
    putData,
    getSecureData,
    postSecureData,
    putSecureData,
    getByIpfsLink,
    showErrorMessage,
    getBlobData,
  };
};
